<template>
    <div class="user-alert-container">
        <input type="checkbox" :checked="user_alert.user_last_login_send" @click="SetAlertSettings()">
        <p>User Alert</p>
    </div>
</template>

<script>
import { BPA } from '@/helpers/BPA'
    export default {
        name: 'UserAlert',
        mixins: [ BPA ],
        props: {},
        data() {
            return {
                user_alert_default: {
                    user_last_login_send: false,
                    user_last_login_after_days: 28,
                },
                user_alert: {}
            }
        },
        async mounted() {
            let currentSettings = await this.GetAlertSettings()
            if(currentSettings) {
                this.user_alert = currentSettings
            }
            else {
                this.user_alert = this.user_alert_default
            }
        },
        methods: {
            async GetAlertSettings() {
               return await BPA.api.GetAlertSettings().then(response => {
                    return BPA.api.response({response, return: 'json'})
                }).then(response => {
                    if (!response.ok || response.status == 204) return;
                    return response.result
                }).catch(e => e)
            },
            async SetAlertSettings() {
                this.user_alert.user_last_login_send = !this.user_alert.user_last_login_send

                let response = await BPA.api.PostAlertSettings({user_last_login_send: this.user_alert.user_last_login_send, user_last_login_after_days: 21,})
                if(response.status == 204) {
                    this.$eventHub.$emit('ShowMessages', {
                    message: 'User alert updated',
                    type: 'success',
                    hide: 4000,
                    });
                }
                else {
                    this.$eventHub.$emit('ShowMessages', {
                    message: 'User alert update failed',
                    type: 'error',
                    hide: 4000,
                    });
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.user-alert-container {
    display: flex;
    flex-direction: row;
    padding: 8px 20px;
}
.user-alert-container > p {
    font-size: 0.8889rem;
    color: #333333;
    margin-left: 8px;
}

</style>