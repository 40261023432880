<template>
  <footer>
      <div>
          © PackShip {{ year }}
      </div>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>

<style scoped>
</style>
