<template>
  <div class="vue-croppie-upload" style="position: relative;">
    <input ref="file" type="file" @change="Load" accept=".jpg, .jpeg, .png" v-show="file" />
    <button ref="crop" @click="Crop" hidden />
    <vue-croppie 
      ref="croppie" 
      customClass="vue-croppie" 
      mouseWheelZoom="ctrl" 
      :enableExif="true" 
      :showZoomer="true" 
      :enableResize="false" 
      :enforceBoundary="true" 
      :enableOrientation="true" 
      :croppieInitialized="Ready" 
      :boundary="boundary" 
      :viewport="viewport" 
      @update="Update" 
      @result="Result" 
    />
    <img :src="image" hidden>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        image: this.src,
        boundary: {
          width: 430,
          height: 430
        },
        viewport: {
          width: 430,
          height: 430,
          type: 'square'
        },
        options: {
          type: 'base64',
          format: 'png',
          size: {
            width: 512,
            height: 512
          }
        },
        init: null,
        file: false,
        update: null,
        dom: {}
      }
    },
    created() {
      /*
      this.update = (e) => {
        console.log(e)
      }
      */
    },
    mounted() {
      const file = this.$refs.file;
      const croppie = this.$refs.croppie;
      this.dom.boundary = this.croppie.querySelector('.cr-boundary');
      let viewport = this.croppie.querySelector('.cr-viewport');
      let slider_wrap = this.croppie.querySelector('.cr-slider-wrap');
      let slider = slider_wrap.querySelector('.cr-slider');
      let div = document.createElement('div');
      let image = this.image;
      Object.assign(file.style, {
        zIndex: 2,
        opacity: 0,
        padding: 0,
        left: '50%',
        width: '430px',
        height: '430px',
        cursor: 'pointer',
        position: 'absolute',
        transform: 'translateX(-50%)'
      });
      if (this.dom.boundary) {
        this.dom.clear = div.cloneNode();
        this.dom.clear.classList.add('cr-clear');
        this.dom.clear.onclick = () => this.Clear();
        this.dom.boundary.appendChild(this.dom.clear);
        if (!image) {
          this.file = true;
          image = this.default;
          this.dom.clear.hidden = true;
          this.dom.boundary.classList.add('background');
        }
      }
      if (viewport) viewport.style.border = 'none';
      if (slider_wrap) {
        const rotation = {};
        for (let direction of ['left', 'right']) {
          rotation[direction] = div.cloneNode();
          let rotate = rotation[direction];
          rotate.classList.add('cr-rotator', direction);
          rotate.onclick = () => this.Rotate(direction == 'right' ? -90 : 90);
        }
        slider.style.height = '30px';
        Object.assign(slider_wrap.style, {
          width: '430px',
          display: 'flex',
          margin: '15px auto 0',
          justifyContent: 'space-between'
        });
        while (slider_wrap.firstChild) {
          slider_wrap.removeChild(slider_wrap.firstChild);
        }
        slider_wrap.append(rotation.left, slider, rotation.right);
      }
      //if (!this.image) this.Default();
      //if (!this.image) return croppie.bind({url: this.default});
      croppie.bind({url: image}).then(() => {
        croppie.result(this.options, image => {
          this.$emit('init', image);
          this.image = image;
        });
      });
    },
    destroyed() {
      //this.croppie.removeEventListener('update', this.Crop);
    },
    model: {
      event: 'crop'
    },
    props: {
      src: {
        default: ''
      },
      default: {
        default: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
      }
    },
    watch: {
      src(src) {
        this.image = src;
      }
    },
    computed: {
      croppie() {
        return document.querySelector('.vue-croppie');
      }
    },
    methods: {
      Default() {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const width = this.boundary.width;
        const height = this.boundary.height;
        context.canvas.width = width;
        context.canvas.height = height;
        context.beginPath();
        context.rect(0, 0, width, height);
        context.fillStyle = 'lightgrey';
        context.fill();
        this.image = canvas.toDataURL('image/png');
        this.$refs.croppie.bind({
          url: this.image
        });
      },
      Load(e) {
        let reader = new FileReader();
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;
        reader.onload = (event) => {
          this.dom.boundary.classList.remove('background');
          this.$refs.croppie.bind({
            url: event.target.result
          }).then(() => {
            this.dom.clear.hidden = false;
            this.file = false;
          });
        };
        reader.readAsDataURL(files[0]);
        //this.croppie.addEventListener('update', this.Crop);
      },
      Crop() {
        this.$refs.croppie.result(this.options, (image) => {
          this.$emit('crop', image);
          this.image = image;
        });
      },
      Ready() {
        
      },
      Update() {
        
      },
      Result() {
        
      },
      Clear() {
        this.$refs.croppie.bind({url: this.default}).then(() => {
          this.dom.boundary.classList.add('background');
          this.$refs.croppie.result(this.options, (image) => {
            this.dom.clear.hidden = true;
            this.$emit('clear', image);
            this.image = image;
            this.file = true;
          });
        });
      },
      Rotate(angle) {
        this.$refs.croppie.rotate(angle);
      }
    }
  }
</script>

<style lang="scss">
  @import '../../assets/style/variables/icons';

  .cr-boundary {
    box-shadow: 0 0 0 1px #eaeaea;

    &.background {
      background-image: $user-image;
    }
  }

  .cr-clear,
  .cr-rotator {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
  }

  .cr-clear::after,
  .cr-rotator {
    background: #7f7f7f;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: 75%;
  }

  .cr-clear {
    top: 10px;
    left: 10px;
    z-index: 1;
    filter: grayscale(1);
    background-clip: revert;
    mix-blend-mode: difference;

    &::after {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      position: absolute;
      mask-image: $trash-alt;
    }
  }

  .cr-rotator {
    &.left {
      mask-image: $undo-alt;
    }

    &.right {
      mask-image: $undo-alt;
      transform: scale(-1, 1);
    }
  }
</style>
