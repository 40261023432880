<template>
  <header id="header" :data-version="version">
    <div id="logo">
      <img :src="company_logo_url" />
    </div>
    <nav ref="nav">
      <router-link :class="['link', {'start-page': item.start_page}]" v-for="(item, index) in items" :key="index" v-show="Check(Object.values(item.role)) && ('check' in item ? item.check : true)" :to="{name: item.code}">
        <img class="icon" :src="require(`@/assets/images/icons/${item.icon}.svg`)">
        <div class="label">{{ item.label }}</div>
      </router-link>
    </nav>
    <div id="user">
      <a class="user-link" @click="ExpandSubmenu">
        <div class="user-image" ref="user_image" />
        <div class="truncate">{{ DisplayName }}</div>
      </a>
      <div id="account-submenu" class="expand-submenu">
        <ul>
          <li v-if="Check(['admin', 'user_alert_mails'])">
            <UserAlert></UserAlert>
          </li>
          <li>
            <a @click.prevent="Profile" href="">{{ $t('My page') }}</a>
          </li>
          <li>
            <a @click.prevent="Logout" class="logout" href="">{{ $t('Logout') }}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="lang-logo-container">
      <div class="language-selector">
        <LocaleChanger expand="up" />
      </div>
      <div id="packship-logo" />
    </div>

    <Modal modal="profile" :value="modal.open" :title="modal.title">
      <div id="my-data" class="scrollable">
        <div class="modal-header"></div>
        <div class="modal-content scrollable" style="padding-bottom: 25px;">
          <div class="information" v-if="modal.active == 'profile'">
            <div class="info-primary" style="justify-content: flex-start;">
              <p class="info-row"><b>{{ $t('User') + ' ID' }}:</b> <span>{{ user.user_id }}</span></p>
              <p class="info-row"><b :style="!user.name && 'opacity: 0.3'">{{ $t('First name') }}:</b> <span v-if="user.name">{{ TitleCase(user.name || '') }}</span></p>
              <p class="info-row"><b :style="!user.surname && 'opacity: 0.3'">{{ $t('Last name') }}:</b> <span v-if="user.surname">{{ TitleCase(user.surname || '') }}</span></p>
              <p class="info-row"><b :style="!user.address && 'opacity: 0.3'">{{ $t('Address') }}:</b> <span v-if="user.address" v-html="Hyperlink({scheme: 'map', target: '_blank', href: String(`${user.address + (user.zipcode || user.city ? ', ' : '')} ${user.zipcode} ${user.city}`).trim(), text: user.address})" /></p>
              <p class="info-row"><b>
                <b :style="!user.zipcode && 'opacity: 0.3'">{{ $t('ZIP code') }}</b>&nbsp;
                <b :style="!(user.zipcode && user.city) && 'opacity: 0.3'"></b>
                <b :style="!user.city && 'opacity: 0.3'">{{ $t('City').toLowerCase() }}</b>
                <b :style="!(user.zipcode || user.city) && 'opacity: 0.3'">:</b></b>&nbsp;
                <span v-if="user.zipcode || user.city" v-html="Hyperlink({scheme: 'map', target: '_blank', href: String(`${user.zipcode} ${user.city}`).trim(), text: `${user.zipcode || ''}${user.city ? `${(user.zipcode ? ' ' : '') + user.city}` : ''}`})" /></p>
              <p class="info-row"><b :style="!user.country && 'opacity: 0.3'">{{ $t('Country') }}:</b> <span v-if="user.country" style="display: flex; align-items: center;">
                <Flag :code="user.country.code" size="small" />
                <span style="margin-left: 10px;" v-html="Hyperlink({scheme: 'map', target: '_blank', href: CountryName(user.country.code)})" /></span>
              </p>
              <p class="info-row"><b :style="!user.email && 'opacity: 0.3'">{{ $t('Email') }}:</b> <span v-html="Hyperlink({scheme: 'mailto', href: String(user.email).toLowerCase()})" /></p>
              <p class="info-row"><b :style="!user.phone && 'opacity: 0.3'">{{ $t('Phone') }}:</b> <span  v-html="Hyperlink({scheme: 'tel', href: (user.prefix_phone || '') + user.phone, text: `${user.prefix_phone ? `(${user.prefix_phone})` : ''} ${user.phone}`})" /></p>
              <p class="info-row"><b :style="!user.employment_day && 'opacity: 0.3'">{{ $t('Start date') }}:</b> <span v-if="user.employment_day">{{ DateOnly(user.employment_day) }}</span></p>
              <p class="info-row"><b :style="!user.permissions && 'opacity: 0.3'">{{ $t('Permissions') }}:</b> <span v-if="user.permissions">{{ $t(user.permissions) }}</span></p>
            </div>
            <div class="info-primary image-cropper" style="padding: 0; border: 0;">
              <VueCroppieUpload ref="image" :src="modal.image.src" @crop="CropImage($event)" @init="InitImage($event)" />
            </div>
          </div>
          <div class="flex row" style="width: 100%; margin-top: 30px;">
            <div class="flex column" style="width: calc(50% - 55px);">
              <h3 class="heading">{{ $t('Preferences') }}</h3>
              <div class="label" style="width: 50%; margin-top: 10px;">
                <div class="label-text">{{ $t('Start page') }}</div>
                <v-select name="start-page" v-model="modal.data.start_page" :options="items.map(page => {page.label = $t(page.label); return page})" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <button class="button green" @click.prevent="SaveProfile">{{ $t('Save') }}</button>
          </div>
        </div>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

    <ValidatePopup />
    <Messages />
    <Alert />
    
  </header>
</template>

<script>
  import VueCroppieUpload from '@/components/snippets/VueCroppieUpload';
  import LocaleChanger    from '@/components/snippets/LocaleChanger';
  import ValidatePopup    from '@/components/snippets/ValidatePopup';
  import Messages         from '@/components/snippets/Messages';
  import Modal            from '@/components/snippets/Modal';
  import Alert            from '@/components/snippets/Alert';
  import Flag             from '@/components/snippets/Flag';
  import UserAlert from '@/components/blocks/User_Alert/UserAlert.vue';
  import { Permissions }  from '@/helpers/Permissions';
  import { Config }       from '@/helpers/Config';
  import { Tool }         from '@/helpers/Tool';
  import { BPA }          from '@/helpers/BPA';
  import conf             from '../../../public/conf.json';

  export default {
    name: 'Header',
    mixins: [Permissions, Config, Tool, BPA],
    components: {
      VueCroppieUpload,
      LocaleChanger,
      ValidatePopup,
      Messages,
      Modal,
      Alert,
      Flag,
      UserAlert
    },
    data() {
      return {
        loading: false,
        dev: Config.dev,
        version: 'v ' + conf.version,
        user: BPA.session.auth().user,
        countries: BPA.api.Countries('GET'),
        image: {b64: '', src: ''},
        data: {},
        items: [],
        nav: [],
        modal: {
          open: false,
          title: '',
          image: {},
          data: {}
        }
      }
    },
    async created() {
      this.nav = [
        {
          role: {
            0: 'admin', 
            1: 'punchclock_admin', 
            2: 'punchclock'
          },
          check: this.CheckIp(),
          icon: 'punchclock',
          code: 'punchclock',
          label: 'Punch clock'
        },
        {
          role: {
            0: 'admin', 
            3: 'scan_admin', 
            4: 'scan'
          },
          check: this.CheckIp(),
          icon: 'barcode',
          code: 'scan',
          label: 'Scan'
        },
        /*
        {
          role: {
            0: 'admin', 
            5: 'trophies_admin', 
            6: 'trophies'
          },
          icon: 'trophy',
          code: 'trophies',
          label: 'Trophies'
        },
        */
        {
          role: {
            0: 'admin', 
            7: 'parcels_admin', 
            8: 'parcels'
          },
          icon: 'parcels',
          code: 'orders',
          label: 'Parcels'
        },
        {
          role: {
            0: 'admin', 
            25: 'sn_admin', 
            26: 'sn'
          },
          icon: 'serial-number',
          code: 'serials',
          label: 'Serial number'
        },
        {
          role: {
            0: 'admin', 
            9: 'users_admin', 
            10: 'users'
          },
          icon: 'users',
          code: 'users',
          label: 'Users'
        },
        {
          role: {
            0: 'admin'
          },
          icon: 'salary',
          code: 'salary-report',
          label: 'Salary'
        },
        {
          role: {
            0: 'admin', 
            11: 'list_admin', 
            12: 'list'
          },
          check: this.IsMainCompany(),
          icon: 'list',
          code: 'picklists',
          label: 'Lists'
        },
        {
          role: {
            0: 'admin', 
            21: 'statistics_admin', 
            22: 'statistics'
          },
          icon: 'chart-bar',
          code: 'picker-stats',
          label: 'Statistics'
        },
        {
          role: {
            0: 'admin', 
            13: 'dashboard_admin', 
            14: 'dashboard'
          },
          icon: 'chart',
          code: 'dashboard-deadline',
          label: 'Dashboard'
        },
        {
          role: {
            0: 'admin', 
            15: 'schedule_admin', 
            16: 'schedule'
          },
          icon: 'calendar',
          code: 'schedule',
          label: 'Schedule'
        },
        {
          role: {
            0: 'admin', 
            7: 'parcels_admin', 
            17: 'settings_admin', 
            18: 'settings'
          },
          icon: 'settings',
          code: 'settings',
          label: 'Settings'
        },
      ];
      this.data = await this.GetMySettings();
      this.items = this.CloneObject(this.nav).map(item => {
        if (this.data.start_page && item.code == this.data.start_page.code) {
          item.start_page = true;
        }
        return item;
      }).filter(item => {
        return this.Check(Object.values(item.role)) && ('check' in item ? item.check : true);
      });
      let start_page = this.items.find(item => item.start_page);
      if (/login/.test(this.$router.history._startLocation)) {
        this.$nextTick().then(() => {
          let first_page = this.$refs.nav.firstChild || {click(){}};
          if (start_page) {
            start_page = document.querySelector('.start-page');
            setTimeout(() => {
              if (start_page) {
                start_page.click();
              } else {
                first_page.click();
              }
            }, 100);
          } else {
            first_page.click();
          }
        });
      }
      let announcements = await this.GetAnnouncements() || [];
      if (announcements.length) {
        let announcement = 1;
        const handle_announcement = async (popup = {}) => {
          return await new Promise((resolve) => {
            for (let tag of ['article', 'dialog', 'header', 'h3', 'h4', 'form', 'footer', 'menu', 'main', 'span', 'div', 'button', 'input', 'p']) {
              popup[tag] = document.createElement(tag);
            }
            
            popup.dialog.id = 'dialog';
            popup.dialog.setAttribute('modal-mode', 'mega');

            popup.form.method = 'dialog';

            popup.article.style.userSelect = 'none';

            popup.h3.textContent = popup.title;

            popup.header.append(popup.h3);

            //popup.text = Tool.ParseMarkDown(popup.text);
            
            if (Tool.IsHtmlString(popup.text)) {
              popup.text = String(popup.text).replace(/\bon[^=]*="[^"]*"|script/gm, '');
            }

            popup.article.innerHTML = popup.text;

            if (announcements.length > 1) {
              popup.h4.textContent = `${announcement}/${announcements.length}`;
              popup.header.append(popup.h4);
            }

            if (popup.required_consent) {
              popup.label = popup.div.cloneNode();
              popup.label.text = popup.span.cloneNode();
              popup.input.wrapper = popup.div.cloneNode();
              popup.button.preview = popup.button.cloneNode();
              
              popup.label.className = 'label required';
              popup.label.style.marginTop = '30px';

              popup.label.text.className = 'label-text';
              popup.label.text.style.textAlign = 'left';
              popup.label.text.textContent = 'Password';

              Object.assign(popup.label.style, {
                width: '100%',
                maxWidth: '500px',
                margin: '20px auto 0'
              });

              popup.input.wrapper.className = 'v-wrapper border';

              popup.input.className = 'v-wrapper-input password';
              popup.input.style.border = 0;
              popup.input.required = true;

              popup.input.onkeydown = (e) => {
                if (e.key == 'Enter') {
                  e.preventDefault();
                  popup.input.blur();
                  popup.button.click();
                }
              }
              
              popup.button.preview.className = 'v-wrapper-input-button icon eye';
              popup.button.preview.title = 'Show';
              popup.button.preview.style.border = 0;

              popup.button.preview.onclick = (e) => {
                e.preventDefault();
                let classList = e.target.classList;
                for (let name of ['eye', 'eye-slash']) {
                  classList.toggle(name);
                }
                let eye_icon = classList.contains('eye');
                popup.input.classList.toggle('password');
                e.target.title = eye_icon ? 'Show' : 'Hide';
              }
              
              popup.input.wrapper.append(popup.input, popup.button.preview);
              popup.label.append(popup.label.text, popup.input.wrapper);
              popup.menu.append(popup.label);
            }

            popup.button.type = 'submit';
            popup.button.className = 'button green';
            popup.button.textContent = 'Okay, I understand';
            popup.button.style.marginTop = '30px';

            popup.button.onclick = async (e) => {
              e.preventDefault();
              let request = {id: popup.id};
              if (popup.required_consent) {
                let value = String(popup.input.value).trim();
                if (!value.replace(/\s/g, '')) {
                  popup.input.setCustomValidity('');
                  popup.input.reportValidity();
                  return popup.input.focus();
                }
                request.body = {password: value};
              }
              let response = await this.ConfirmAnnouncement(request);
              if (!response.ok) {
                if (popup.required_consent) {
                  let message = String(response.message).replace(/^.+ - /, '');
                  popup.input.setCustomValidity(message);
                  popup.input.reportValidity();
                }
              } else {
                popup.dialog.close();
              }
            }

            popup.footer.style.paddingTop = '10px';
            popup.footer.style.paddingBottom = 0;

            popup.menu.append(popup.button);
            popup.footer.append(popup.menu);

            popup.form.append(popup.header, popup.article, popup.footer);
            popup.dialog.append(popup.form);

            let existing_dialog = document.querySelector('dialog');
            if (existing_dialog) existing_dialog.remove();
            document.body.append(popup.dialog);
            popup.dialog.showModal();

            popup.dialog.addEventListener('cancel', (e) => {
              e.preventDefault();
            });

            popup.dialog.addEventListener('close', (e) => {
              e.preventDefault();
              resolve(true);
            });
          });
        }
        for (let popup of announcements) {
          await handle_announcement(popup);
          announcement++;
        }
      }

      document.addEventListener('click', this.ResetSubmenu);
      this.image.src = await this.GetUserImage(this.user.user_id);
      if (this.image.src) {
         this.$refs.user_image.style.setProperty('--src', `url(${this.image.src})`);
      } else {
        this.$refs.user_image.style.setProperty('--pos', 'center 5px');
        this.$refs.user_image.style.setProperty('--size', '110%');
      }
    },
    async mounted() {
      this.$eventHub.$on('CloseModal', () => this.modal.open = false);
      this.submenu = document.getElementById('account-submenu');
      this.submenu.querySelectorAll('a').forEach(link => {
        link.addEventListener('click', () => {
          this.submenu.classList.remove('visible');
        });
      });
    },
    computed: {
      company_logo_url() {
        return BPA.util.GetCompanyLogo(BPA.util.GetCompany()) + '?' + new Date().getTime();
      },
      DisplayName() {
        return Tool.TitleCase(String(this.user.display_name)).split(' ')[0];
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        let nav = this.CloneObject(this.nav);
        for (let item of this.items) {
          let nav_item = nav.find(nav_item => {
            return nav_item.code == item.code;
          });
          item.label = this.$t(nav_item.label);
        }
      }
    },
    methods: {
      ConsoleLog(output) {
        console.log(output);
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      DateFormat(date) {
        return Tool.DateFormat(date);
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      Capitalize(string = '') {
        return Tool.Capitalize(string);
      },
      TitleCase(string = '') {
        return Tool.TitleCase(string);
      },
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      CountryName(country_code = '') {
        return Tool.CountryName(country_code);
      },
      LanguageName(country_code = '') {
        return Tool.LanguageName(country_code);
      },
      DateOnly(date) {
        date = date + ' 00:00:00';
        date = Tool.DateFormat(date).replace(Tool.TimeOnly(date), '');
        return date.replace(/,(?=[^,]*$)/, '');
      },
      async GetUserImage(user_id) {
        return await new Promise((resolve, reject) => {
          BPA.api.GetUserImage(user_id).then(response => {
            return BPA.api.response({response, return: 'blob',
              error: (message) => {
                if (response && response.status == 404) return response;
                this.$eventHub.$emit('ShowMessages', {
                  message: message,
                  type: 'error',
                  close: true
                });
              }
            });
          }).then(response => {
            if (!response.ok || !response.result) return reject();
            let blob = response.result;
            let reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          }).catch(reject);
        }).catch(e => e);
      },
      async UpdateUserImage(user) {
        return await BPA.api.UpdateUserImage(user).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async DeleteUserImage(user_id) {
        return await BPA.api.DeleteUserImage(user_id).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      ExpandSubmenu(event) {
        event.currentTarget.nextSibling.classList.toggle('visible');
      },
      ResetSubmenu(event) {
        if (!event.target.closest('#user')) {
          if (this.submenu.classList.contains('visible')) {
            this.submenu.classList.toggle('visible');
          }
        }
      },
      async Profile() {
        this.modal.active = 'profile';
        let user = await this.GetMyData();
        let country = this.countries.find(option => option.id == user.res_country_id);
        if (country) user.country = {id: country.id, code: country.iso_code_2, name: country.name};
        this.user = {...this.user, ...user};
        delete this.user.permission_ids;
        this.user.permission = user.permission_ids;
        BPA.storage.setItem('user', JSON.stringify(this.user));
        this.user.permissions = Object.values(BPA.permissions(user.permission_ids)).map(role => {
          let role_name = role.split('_')[0] || '';
          if (/punchclock/.test(role_name)) role_name = 'punch clock';
          return this.$t(Tool.Capitalize(role_name)) + (/_admin/.test(role) ? ' (Admin)' : '');
        }).sort().join(', ');
        this.modal.image = this.CloneObject(this.image);
        this.modal.data = this.CloneObject(this.data);
        this.modal.title = this.$t('My page');
        this.modal.open = true;
      },
      async SaveProfile() {
        let image = null;
        let success = null;
        let changed = null;
        this.$refs.image.Crop();
        await new Promise(resolve => {
          setTimeout(() => {
            resolve(image = this.modal.image);
          }, 100);
        });
        let data = {
          new: this.CloneObject(this.modal.data),
          old: this.CloneObject(this.data)
        };
        let update_image = image && image.src != image.b64;
        this.loading = true;
        if (update_image) {
          let rgb = this.GetAverageRGB(image.b64);
          let user_image = this.$refs.user_image;
          if (!rgb.every(value => value === '0')) {
            if ((success = await this.UpdateUserImage({
              id: this.user.user_id, 
              image: image.b64.split('base64,')[1]
            }))) {
              this.image.src = this.modal.image.b64;
              user_image.style.setProperty('--src', `url(${this.image.src})`);
              user_image.style.removeProperty('--pos');
              user_image.style.removeProperty('--size');
            }
            changed = true;
          } else if (this.image.src) {
            if ((success = await this.DeleteUserImage(this.user.user_id))) {
              user_image.style.setProperty('--pos', 'center 5px');
              user_image.style.setProperty('--size', '110%');
              user_image.style.removeProperty('--src');
              this.image.src = '';
            }
            changed = true;
          }
        }
        if (JSON.stringify(data.new) != JSON.stringify(data.old)) {
          success = await this.SetMySettings(data.new);
          this.data = data.new;
          changed = true;
        }
        this.loading = false;
        if (changed) {
          if (success) {
            this.$eventHub.$emit('CloseModal', 'profile');
            this.$eventHub.$emit('ShowMessages', {
              message: 'Profile successfully updated',
              type: 'success',
              hide: 2000
            });
          }
        } else {
          this.$eventHub.$emit('CloseModal', 'profile');
          this.$eventHub.$emit('ShowMessages', {
            message: 'No changes made',
            type: 'warning',
            hide: 2000
          });
        }
      },
      async Logout() {
        await BPA.session.Logout();
      },
      GetAverageRGB(base64) {
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        let image = new Image();
        image.crossOrigin = '';
        image.src = base64;
        context.imageSmoothingEnabled = true;
        context.drawImage(image, 0, 0, 1, 1);
        return context.getImageData(0, 0, 1, 1).data.slice(0, 3).toString().split(',');
      },
      ResetImage() {
        this.image = {src: '',  b64: ''};
      },
      InitImage(image) {
        let rgb = this.GetAverageRGB(image);
        if (rgb.every(value => value === '0')) {
          image = '';
        }
        this.modal.image.src = image;
      },
      CropImage(image) {
        this.modal.image.b64 = image;
      },
      Check(required) {
        return BPA.permissions(required).length;
      },
      CheckIp() {
        const IP = BPA.util.GetStoredIP();
        return Object.keys(IP).length == 2 && Config.ValidatePublicIpAddress(IP.packing, IP.public);
      },
      IsMainCompany() {
        return BPA.util.IsMainCompany();
      },
      async GetAnnouncements() {
        return await BPA.api.GetAnnouncements().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          return response.result;
        }).catch(e => e);
      },
      async ConfirmAnnouncement(request = {}) {
        return await BPA.api.ConfirmAnnouncement(request).then(response => {
          return BPA.api.response({response, error: (message) => {
            return (response.message = message);
          }});
        }).then(response => response).catch(e => e);
      },
      async GetMyData() {
        return await BPA.api.GetMyData().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          return response.result || {};
        }).catch(e => e);
      },
      async GetMySettings(key = '') {
        return await BPA.api.GetMySettings(key).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          return response.result || {};
        }).catch(e => e);
      },
      async SetMySettings(data = {}) {
        return await BPA.api.SetMySettings(data).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      }
    }
  }
</script>

<style lang="scss" scoped>
  header {
    padding-top: 10px;
    position: relative;

    &::after {
      bottom: 0;
      height: 2em;
      flex-shrink: 0;
      line-height: 2em; 
      font-size: 0.875em;
      color: #C2C8CE;
      font-weight: 600;
      position: relative;
      text-align: center;
      /*letter-spacing: 0.5px;*/
      content: attr(data-version);
      /*transform: translateY(calc(100% - 1em / 2));*/
    }
  }
</style>
