<template>
  <transition name="fade">
    <div class="alert-popup" v-if="show" @click="ClickAround" tabindex="0">
      <div class="alert-content">
        <div class="alert-title" v-if="title">
          <h3>{{ title }}</h3>
        </div>
        <div class="alert-message" v-if="message">
          <div v-if="html" v-html="message" class="alert-html"></div>
          <div v-else class="alert-body" :style="{textAlign: center ? 'center' : 'left'}">{{ message }}</div>
        </div>
        <div class="alert-action">
          <button class="alert-button" @click="HideAlert">{{ button }}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
		name: 'Alert',
		data() {
			return {
        show: false,
        html: false,
        title: null,
        center: false,
        message: null,
        persist: false,
        prevent: false,
        button: 'Okay'
			}
		},
		created() {
			this.$eventHub.$on('ShowAlert', (data = {}) => {
        this.show = true;
        this.title = data.title;
        this.center = data.center;
        this.persist = data.persist;
        this.prevent = data.prevent;
				if ('message' in data) {
          let html = new DOMParser().parseFromString(data.message, 'text/html');
          this.html = Array.from(html.body.childNodes).some(node => node.nodeType == 1);
					this.message = String(data.message).trim();
        }
        if ('button' in data) {
          this.button = data.button.replace(/\s\s+/g,' ').trim();
        }
        document.activeElement.blur();
        if (this.persist == true) return;
				window.addEventListener('keyup', this.HideAlert);
      });
      this.$eventHub.$on('HideAlert', this.Unsubscribe);
		},
		destroyed() {
			this.Unsubscribe();
		},
		methods: {
			Unsubscribe() {
        this.show = false;
        window.removeEventListener('keyup', this.HideAlert);
      },
      ClickAround(e) {
        if (this.persist == true) return;
        if (e.target && e.target.classList.contains('alert-popup')) {
          this.$eventHub.$emit('HideAlert');
        }
      },
			HideAlert(e) {
        if (this.prevent == true) return;
        if (!['keyup', 'click'].includes(e.type)) return;
        if (e.type == 'keyup' && e.keyCode && ![13, 27].includes(e.keyCode)) return;
        if (e.type == 'click' && e.target && !e.target.classList.contains('alert-button')) return;
        this.$eventHub.$emit('HideAlert');
			}
		}
  }
</script>

<style lang="scss" scoped>
  .alert {
    &-popup {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      position: fixed;
      align-items: center;
      justify-content: center;
      z-index: 999999;

      &, * {
        box-sizing: border-box;
      }

      &:after {
        top: 0px;
        left: 0px;
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }

    &-content {
      z-index: 1;
      padding: 20px;
      min-width: 325px;
      max-width: 650px;
      border-radius: 10px;
      background-color: #ffffff;
      transition: all .2s ease-in-out;
      /*box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.05);*/
    }

    &-title + &-message,
    &-message + &-action {
      margin-top: 30px;
    }

    &-title {
      margin: -5px 0;
      user-select: none;
    }

    &-message {
      padding: 10px;
      overflow: auto;
      word-break: break-word;
      overflow-wrap: break-word;
      border-radius: 5px;
      /*border: 1px solid lightgray;*/
      background-color: rgba(0, 0, 0, 0.05);
     /* box-shadow: inset 1px 1px 2px 0px rgba(0, 0, 0, 0.15);*/
    }

    &-body {
      white-space: pre;
    }

    &-action {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    &-button {
      border: none;
      outline: none;
      min-width: 10em;
      color: #ffffff;
      cursor: pointer;
      appearance: none;
      user-select: none;
      font-weight: bold;
      padding: 10px;
      text-align: center;
      border-radius: 10px;
      font-size: 0.7778rem;
      text-decoration: none;
      background-color: #1b577a;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &.red {
        background-color: #FF686E;
      }

      &.yellow {
        background-color: #FFDC00;
      }

      &.green {
        background-color: #58BF5D;
      }
    }
  }
</style>