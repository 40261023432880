<template>
  <div class="locale-changer" :style="{width: width}">
    <div class="s-select" :class="{simple: simple}">
      <div class="s-select__toggle" :class="expand" :title="simple && $t(selected.label)" @click="Toggle">
        <Flag :code="selected.code" size="small" type="language" />
        <div class="option-label" v-if="!simple">{{ $t(selected.label) }}</div>
      </div>
      <div class="s-select__list">
        <ul class="s-select__list-box">
          <li class="s-select__list-box--item" :class="{selected: selected.code == option.code}" v-for="(option, i) in options" :key="i" :title="simple && $t(option.label)" @click="LocaleChange(option), Toggle()">
            <Flag :code="option.code" size="small" type="language" />
            <div class="option-label" v-if="!simple">{{ $t(option.label) }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import Flag from '@/components/snippets/Flag';
  import {BPA} from '@/helpers/BPA';

  export default {
    name: 'LocaleChanger',
    components: {Flag},
    data() {
      return { 
        options: [],
        width: null
      }
    },
    props: {
      simple: {
        default: false
      },
      expand: {
        default: 'down'
      }
    },
    computed: {
      locale() {
        return this.$i18n.locale;
      },
      toggle() {
        return document.querySelector('.s-select__toggle');
      },
      selected() {
        return this.options.find(option => option.code == this.locale);
      }
    },
    watch: {
      locale() {
        this.SetWidth();
      }
    },
    created() {
      this.options = BPA.locale('options').map(option => {
        option.label = option.label.split(' ')[0];
        return option;
      });
      const mouse = {
        _vm: this,
        event: {
          down(event) {
            mouse.toggle(event.target);
          },
          up(event) {
            mouse.toggle(event.target);
          }
        },
        toggle(element) {
          if (!this._vm.selector.includes(element)) {
            this._vm.toggle.classList.remove('expanded');
          }
        },
        listener(argument) {
          const subscribe = argument !== false;
          if (subscribe && this.listener.subscribed) return;
          const subscription = subscribe ? 'add' : 'remove';
          const method = subscription + 'EventListener';
          Object.keys(this.event).map(event => window[method]('mouse' + event, this.event[event]));
          if (!subscribe) for (let prop in this) if (!/event|listener/.test(prop)) delete this[prop];
          this.listener.subscribed = subscribe;
        }
      }
      this.mouse = mouse;
    },
    mounted() {
      this.MouseListener(true);
    },
    destroyed() {
      this.MouseListener(false);
    },
    methods: {
      MouseListener(subscribe) {
        if (this.mouse) {
          if (subscribe) {
            this.selector = Array.from(document.querySelectorAll('.locale-changer, .locale-changer *'));
            setTimeout(this.SetWidth);
          }
          this.mouse.listener(subscribe);
        }
      },
      SetWidth() {
        if (!this.simple) this.$nextTick().then(() => {
          this.width = this.selector.find(e => e.className.endsWith('item')).clientWidth + 'px';
        });
      },
      Toggle() {
        this.toggle.classList.toggle('expanded');
      },
      LocaleChange(option) {
        if (!option) return;
        let messages = this.$i18n.messages;
        const locale = JSON.parse(BPA.storage.getItem('locale'));
        locale.code = option.code;
        if (!locale.loaded.includes(locale.code)) {
          window.fetch(`/translations/${locale.code}.json?${new Date().getTime()}`).then(response => {
            if (response && response.status == 200) return response.json();
          }).then(json => {
            if (!json) return;
            this.$i18n._vm.messages = {...messages, ...json};
            this.$i18n._vm.locale = locale.code;
            locale.loaded.push(locale.code);
            this.SetLocaleCode(locale);
          }).catch(error => error);
          return;
        }
        this.$i18n.locale = locale.code;
        this.SetLocaleCode(locale);
      },
      SetLocaleCode(locale) {
        BPA.storage.setItem('locale', JSON.stringify(locale));
        document.documentElement.lang = locale.code.split('_')[0];
        window.locale = this.options.find(option => option.code == locale.code).code.replace(/_/g, '-');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .flag {
    &-icon {
      flex-shrink: 0;
      position: relative;
    }
  }

  .s-select {
    position: relative;
    
    &__toggle {
      display: flex;
      padding: 0 4px;
      position: relative;
      align-items: center;
      background-color: white;
      cursor: pointer;
    }

    &__list {
      padding: 0;
      max-height: 0;
      display: flex;
      overflow: hidden;
      list-style: none;
      position: absolute;
      align-items: flex-end;
      flex-direction: column;
      background-color: white;
      //transition: max-height 0.15s ease;

      &-box {
        top: 0;
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-shrink: 0;
        position: relative;
        //align-items: flex-end;
        flex-direction: column;

        &--item {
          display: flex;
          flex-shrink: 0;
          padding: 0 4px;
          position: relative;
          align-items: center;
          cursor: pointer;
          background-color: white;

          &:hover {
            background-color: darken(white, 10%);
          }

          &.selected {
            color: white;
            background-color: blue;

            &:hover {
              background-color: darken(blue, 10%);
            }
          }
        }
      }
    }

    &__toggle, &__list {
      overflow: hidden;
      user-select: none;
      border-radius: 4px;
      background-color: white;
      box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.15);
    }

    &__list {
      pointer-events: none;
    }

    &__toggle.expanded {
      border-radius: 4px 4px 0 0;
      box-shadow: inset 0 0 0 1px #0000ff, 1px 2px 6px 0 rgba(0, 0, 0, 0.15);
    }

    &__toggle.expanded + &__list {
      max-height: 125px;
      pointer-events: all;
      border-radius: 0 0 4px 4px;
    }

    &__toggle.expanded.up {
      z-index: 1;
      border-radius: 0 0 4px 4px;
      
      & + .s-select__list {
        transform: translateY(calc(-100% - 25px));
        border-radius: 4px 4px 0 0;
      }
    }

    .flag-icon {
      pointer-events: none;
    }

    .option-label {
      user-select: none;
      pointer-events: none;
    }

    .flag-icon + .option-label {
      margin-left: 8px;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .v-select {
    .flag-icon {
      float: left;
      margin-right: 10px;
    }
  }
</style>