<template>
  <transition name="fade">
    <div @click="Around" class="validate-popup" v-if="flag">
      <div class="validate-popup__content">
        <div class="titles">
          <p class="title">Are you sure?</p>
          <div class="subtitle" v-html="message" />
        </div>
        <div class="actions">
          <a class="btn-stateless" @click.prevent="Validate(false)" href="">{{ disapprove }}</a>
          <a :class="'btn-'+ type" @click.prevent="Validate(true)" href="">{{ approve }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'ValidatePopup',
    data () {
      return {
        flag: false,
        disapprove: null,
        approve: null,
        type: 'stateless',
        message: 'This action can not be reverted.'
      }
    },
    created() {
      let self = this;
      this.$eventHub.$on('ValidateModalStart', (data) => {
        self.flag = true;
        self.disapprove = data.disapprove;
        self.approve = data.approve
        if ('message' in data) {
          self.message = data.message;
        }
        if ('type' in data) {
          self.type = data.type;
        }
        window.addEventListener('keydown', e => this.Esc(e, data));
      });
    },
    destroyed() {
      window.removeEventListener('keydown', this.Esc);
    },
    methods: {
      async Validate(state) {
        this.flag = false;
        window.removeEventListener('keydown', this.Esc);
        await this.$eventHub.$emit('ValidateModalStop', state);
      },
      Around(event) {
        if (event.target.classList.contains('validate-popup')) {
          this.Validate(false);
        }
      },
      Esc(event, data = {}) {
        if ('esc' in data && data.esc == false) return;
        if (event.keyCode == 27) this.Validate(false);
      }
    }
  }
</script>

<style scoped>
</style>